@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

* {
  box-sizing: border-box;
}

.resizing {
  pointer-events: none; /* Disable all interactions */
  user-select: none; /* Prevent text selection */
  cursor: ew-resize; /* Ensure the cursor remains as ew-resize */
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiAutocomplete-popper {
  z-index: 3000 !important;
}

.MuiPopover-root {
  z-index: 3000 !important;
}


.firebase-emulator-warning { display: none; }

.qanda-pdf-marker{
  cursor: pointer;
  /*background-color: rgba(97, 106, 107, 0.7);*/
}

.qanda-pdf-marker:hover{
  transform: scale(1.1);
}

.qanda-pdf-marker:active{
  transform: scale(0.9);
}

.qanda-pdfViewer__noCursor{
  /*pointer-events: none;*/
  cursor: none !important;
  /*user-select: none !important;*/
}

.qanda-pdfViewer__noCursorNoEvents{
  pointer-events: none;
  cursor: none !important;
  user-select: none !important;
}

.qanda-pdfViewer__addMode{
  cursor: none !important;
  user-select: none !important;
}

.rpv-core__page-layer{
  box-shadow: none !important;
}

.rpv-core__annotation-layer {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.rpv-core__canvas-layer{
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.rpv-core__inner-page{
  background-color: #E5E7E9 !important;
  box-sizing: border-box !important;
}

.rpv-core__spinner {
  visibility: hidden !important;
}

.rpv-core__inner-pages {
  background-color: #E5E7E9 !important;
}

.rpv-core__viewer {
  background-color: #E5E7E9 !important;
}

/*.rpv-core__text-layer-loader {*/
/*  visibility: hidden !important;*/
/*}*/

/*.rpv-core__canvas-layer-loader {*/
/*  visibility: hidden !important;*/
/*}*/

/*.rpv-core__annotation-layer-loader {*/
/*  visibility: hidden !important;*/
/*}*/


.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {
    content: attr(data-value) !important;
}

.ql-picker-options{
  max-height: 100px;
  overflow: scroll;
}


.ql-toolbar.ql-snow {
  border: 0px !important;
  border-bottom: 1px solid lightgray !important;
}

.ql-container.ql-snow {
  border: 0px !important;
}

pre {
  margin: 0;
  max-width: 100%;
  box-sizing: border-box;
  white-space: pre-wrap;
}

/*.ql-snow .ql-editor pre.ql-syntax {*/
/*  background-color: black;*/
/*}*/

/* Hide scrollbar for Chrome, Safari and Opera */
.qanda-hideScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.qanda-hideScrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

